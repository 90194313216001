import React, { useCallback, useState } from "react";
import { Editor, Element, Path, Transforms } from "slate";
import { EditorPlugin } from "../../../../../toolympus/components/PowerDoc";
import { generateCode } from "../../../../../toolympus/api/data";
import { ReactEditor } from "slate-react";
import { LabelOutlined } from "@mui/icons-material";
import { createWithVoidInjector } from "../../../../../toolympus/components/PowerDoc/plugins/common";
import { TopicLinkBlock, TopicLinkBlockType } from "./TopicLinkBlock";
import { NewTopicLinkDialog } from "./TopicLinkDialog";
import { Topic } from "../useManageTopics";

interface EditingContext {
  editor: Editor;
}

const withLinkedTopics = createWithVoidInjector(TopicLinkBlockType);

const countSelectedBlock = (editor: ReactEditor): number | null => {
  const selection = editor.selection;

  if(!selection
    || !selection.anchor.path || !selection.focus.path
    || selection.anchor.path[0] === selection.focus.path[0]) {
    return 1;
  } else {
    const start = Math.min(selection.anchor.path[0], selection.focus.path[0]);
    const end = Math.max(selection.anchor.path[0], selection.focus.path[0]);

    let count = 0;
    for (let i = start; i <= end; i++) {
      const node = editor.children[i];
      if(node && (node as any).type !== TopicLinkBlockType) {
        count++;
      }
    }

    return count || 1;
  }
}

const insertTopicLinkBlock = (editor: ReactEditor, topicId: number) => {
  // find current and element and
  // insert the link at elements path (i.e. right before it)
  let path: Path | undefined = undefined;
  const [foundCurrentElement] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n),
  });
  if(foundCurrentElement) {
    path = foundCurrentElement[1];
  }

  Transforms.insertNodes(editor, {
    type: TopicLinkBlockType,
    topic_id: topicId.toString(),
    block_id: generateCode(),
    blocks_count: countSelectedBlock(editor),
    children: [{ text: "", }],
} as any,
{ at: path })
}

export const findCurrentElement = (editor: Editor) => {
  const [found] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n),
  });
  
  if(found) {
    return found[0] as Element;
  } else {
    return undefined;
  }
}

export const useTopicLinksEditorPlugin = (): EditorPlugin => {
  const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
  // const links = useInboundLinks();

  const selectTopic = useCallback((t: Topic) => {
    if(editingContext) {
      insertTopicLinkBlock(editingContext.editor, t._id);
      setEditingContext(null);
    }
  }, [editingContext]);

  const dialogs = (<>
    <NewTopicLinkDialog
      isOpen={!!editingContext}
      close={() => setEditingContext(null)}
      selectTopic={selectTopic}
      />
    </>);

  return {
    key: "topic-links",
    commands: [{
        name: "insert-topic-link",
        invoke: (editor) => {
          setEditingContext({ editor })
        },
        menu: { section: "insert-item", icon: <LabelOutlined />, label: "Ссылка на тему" },
        hotkey: "alt+t",
    }],
    inject: withLinkedTopics,
    customBlocks: { [TopicLinkBlockType]: TopicLinkBlock },
    dialogs,
  }
}
