import React from "react";
import { PowerEditorBaseProps, PowerEditorBase, EditorPlugin } from "../../toolympus/components/PowerDoc";
import { ColumnsPlugin } from "../../toolympus/components/PowerDoc/plugins/Columns";
import { CustomFieldsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { ExpanderPlugin } from "../../toolympus/components/PowerDoc/plugins/Expander";
import { useMediaFilesPlugin } from "../../toolympus/components/PowerDoc/plugins/MediaFiles";
import { PanelPlugin } from "../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin";
import { ScriptPlugin } from "../../toolympus/components/PowerDoc/plugins/Script";
import { TablesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tables";
import { TilesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tiles";
import { createWrappedPowerEditor, PowerEditorSettingsProvider } from "../../toolympus/components/PowerDoc/PowerEditorSettingsContext";
import { useTopicLinksEditorPlugin } from "./Dictionaries/Topics/Editor/useTopicLinksEditorPlugin";
import { useLinksPlugin } from "../../toolympus/components/PowerDoc/plugins/Links";
import { useInlineImagesPlugin } from "../../toolympus/components/PowerDoc/plugins/InlineImages";
import { LineBreaksPlugin } from "../../toolympus/components/PowerDoc/plugins/LineBreaks";
import { BasicElementsPlugin } from "../../toolympus/components/PowerDoc/plugins/BasicElements";
import { BasicCommandsPlugin } from "../../toolympus/components/PowerDoc/plugins/BasicCommands";
import { PasteHtmlPlugin } from "../../toolympus/components/PowerDoc/plugins/PasteHtml";
import { useTextClassPlugin } from "../../toolympus/components/PowerDoc/plugins/BasicCommands/useTextClassPlugin";

export const MpEditorUnwrapped = (props: PowerEditorBaseProps & { extraPlugins?: EditorPlugin[] }) => {
  const topicLinksPlugin = useTopicLinksEditorPlugin();

  const linksPlugin = useLinksPlugin();
  const inlineImagesPlugin = useInlineImagesPlugin();

  return (
      <PowerEditorBase
          {...props}
          noStockPlugins
          plugins={[
              topicLinksPlugin,
              inlineImagesPlugin,
              PanelPlugin,
              TablesPlugin,
              linksPlugin,
              LineBreaksPlugin,
              BasicElementsPlugin,
              BasicCommandsPlugin,
              PasteHtmlPlugin,
              ...(props.extraPlugins || []),
          ]}
          />
  );
}

// export const MpEditor = createWrappedPowerEditor({
//   EditorComponent: MpEditorUnwrapped
// });

export const MpEditor = (props: PowerEditorBaseProps & { extraPlugins?: EditorPlugin[] }) => {
  return (
    <PowerEditorSettingsProvider EditorComponent={MpEditorUnwrapped} >
      <MpEditorUnwrapped {...props} />
    </PowerEditorSettingsProvider>)
}


export const MpCmsEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const textClassPlugin = useTextClassPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              textClassPlugin,
          ]}
          />
  );
}

export const MpCmsEditor = createWrappedPowerEditor({
  EditorComponent: MpCmsEditorUnwrapped
});

