import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteButton, Form, FormGrid, MoreActionsMenuButton, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { useDocumentEdit } from './useDocumentEdit';
import { MpEditor } from '../Administration/MpEditor';
import { AttachedDocumentsBound } from '../../toolympus/components/AttachedDocuments';
import { DocumentApiPath } from './useDocumentsList';
import { UserCheckStatus } from '../../toolympus/components/primitives/UserCheckStatus';
import { useUser } from '../../toolympus/userContext/UserContext';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentStatus } from './DocumentStatus';
import { ContentWithModerationWrapper, DocumentModerationPane } from './DocumentModerationPane';
import { FileUploadDialog } from '../../toolympus/components/files';
import { EditorPlugin } from '../../toolympus/components/PowerDoc';
import { getCurrentFirstLevelElement } from '../../toolympus/components/PowerDoc/slate/SlateHelpers';
import { PowerEditorExternalControlProviderBase } from '../../toolympus/components/PowerDoc/slate/PowerEditorControlContext';


export const DocumentEditForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useDocumentEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });
  const user = useUser();

  useSaveable(data);

  const [isModerationFeedVisible, setIsModerationFeedVisible] = useState<boolean | null>(null);
  useEffect(() => {
    if(isModerationFeedVisible === null && data.moderationFeed.tasks.data.find(t => !t.is_resolved)) {
      setIsModerationFeedVisible(true);
    }
  }, [data.moderationFeed.tasks, isModerationFeedVisible]);

  const tabs = useTabsState([
    ["attributes","Атрибуты"],
    ["content","Содержимое"],
    ["files","Файлы"],
  ], "attributes", "tab");

  const startCommentCommandPlugin: EditorPlugin = {
    key: "start-comment",
    commands: [{
      name: "start-comment",
      menu: { section: "insert-item", label: "Добавить комментарий" },
      invoke: (editor) => {
        setIsModerationFeedVisible(true);
        const path = editor.selection?.anchor;
        
        const commentData: any = {};
        if(path) {
          commentData.editor_path = path;
        }
        const element = getCurrentFirstLevelElement(editor);
        const id = (element as any)?._id;
        if(id) {
          commentData.editor_id = id;
        }

        data.moderationFeed.newComment.startEditing({
          comment: "",
          extra: commentData,
        });
      }
    }],
  }

  return (
    <PowerEditorExternalControlProviderBase>
      <Form
        title="Документ"
        headerItems={<>
          <OccupyFreeSpace />
          {data.hasChanges && <SaveButton action={() => data.save()} />}

          <MoreActionsMenuButton
            strippedButton
            actions={[
              ["content-upload", "Импорт содержимого из PDF", () => data.contentFile.dialog.open(), { disabled: data.hasChanges }],
            ]}
            />

          <DeleteButton
            remove={() => data.remove()}
            title="Удалить документ?"
            button={<IconButton size="small"><DeleteOutlined /></IconButton>}
            />
          <PluggableDocumentationButton documentationKey="documents" />
        </>}
        >
        <FormGrid columns="2fr 1fr 1fr" style={{ alignItems: "flex-end" }}>
          {controls([
            ["title"],
            ["document_type", { autoComplete: true }],
          ])}

          <DocumentStatus
            document={data.data}
            schema={data.schema}
            updateStatus={data.updateDocumentModeration}
            />
        </FormGrid>

        <TabsHeader state={tabs} />

        <TabPanel state={tabs.forTab("attributes")}>
          <FormGrid columns="repeat(4,1fr)" forceEvenColumns>
            {controls([
              ["document_date"],
              ["signed_date"],
              ["entry_into_force_date"],
              ["document_status"],
            ])}
          </FormGrid>

          <FormGrid noMargin columns="1fr 1fr">
            <Autocomplete
              freeSolo
              value={data.data.source_title || ""}
              onChange={(e, newValue) => data.update({ source_title: newValue || "" })}
              onInputChange={(e, newValue) => {
                if(e) {
                  data.update({ source_title: newValue || "" });
              }}}
              autoHighlight
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField
                  error={!!data.errors?.fieldHasErrors("source_title")}
                  {...params}
                  size="small"
                  margin="none"
                  label={data.schema?.source_title?.label}
                  />)}
              options={data.sourceTitleOptions}
              />
            {controls([
              ["source_url"],
            ])}

            <FormGrid columns="1fr 1fr" noMargin>
              {controls([
                ["owner_id", { autoComplete: true }],
                ["moderator_id", { autoComplete: true }],
              ])}
            </FormGrid>

            <UserCheckStatus
              isChecked={!!data.data.actualized_at}
              isCheckedAt={data.data.actualized_at}
              isCheckedBy={data.data.actualized_by}
              isLoading={data.isLoading}
              checkLabel="Подтвердить актуальность"
              uncheckLabel="Подтвердить актуальность"
              doCheck={() => data.save({ actualized_by: user.user?._id })}
              doUncheck={() => data.save({ actualized_by: user.user?._id })}
              checkedLabel="Актуальность проверена:"
              uncheckedLabel="Актуальность неизвестна"
              canControl
              usersDictionaryName="Users"
              wrapperStyle={{ alignSelf: "flex-end" }}
              />
          </FormGrid>

          <FormGrid columns="1fr 1fr 1fr 1fr">
            {controls([
              ["created_by", { disabled: true }],
              ["created_at", { disabled: true, utcToLocal: true }],
              ["updated_by", { disabled: true }],
              ["updated_at", { disabled: true, utcToLocal: true }],
            ])}
          </FormGrid>

          
        </TabPanel>

        <TabPanel state={tabs.forTab("content")}>
          <ContentWithModerationWrapper isFeedVisible={isModerationFeedVisible || false}>
            <MpEditor
              key={`${data.data._id}_${data.loadBumper || ""}`}
              content={data.data.content}
              update={v => data.update({ content: v })}
              editorElementId="doc-editor"
              extraPlugins={[startCommentCommandPlugin]}
              />

            <DocumentModerationPane
              data={data.moderationFeed}
              isFeedVisible={isModerationFeedVisible || false}
              setIsFeedVisible={setIsModerationFeedVisible}
              loadBumper={(data.loadBumper || 0) + (data.moderationFeed.tasks.loadBumper || 0)}
              />

          </ContentWithModerationWrapper>
        </TabPanel>

        <TabPanel state={tabs.forTab("files")}>
          <DocumentAttachments caseRecordId={data.data._id} />
        </TabPanel>


        <FileUploadDialog
          data={data.contentFile}
          title="Загрузить содержимое">
          <Typography>
            Загрузите PDF-файл с помощью поля ниже, чтобы импортировать содержимое документа из него.
          </Typography>
          <Typography>
            Загруженный файл также будет добавлен на вкладку "Файлы".
          </Typography>
          <Typography>
            Внимание: если в документе уже есть содержимое, оно будет перезаписано.
          </Typography>
        </FileUploadDialog>

      </Form>
    </PowerEditorExternalControlProviderBase>
  );
}


const DocumentAttachments = (props: { caseRecordId: number }) => {
  return <AttachedDocumentsBound
    apiPath={DocumentApiPath}
    parentId={props.caseRecordId}
    documentLinkPrefix="/api/document/public/attachment"
    />
}