import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from 'slate-react';
import { Chip } from '@mui/material';
import { Buttons } from '../../../../../toolympus/components/primitives';
import { DictionarySelectDisplay } from '../../../../../toolympus/components/schemed/Select';
import { LabelOutlined } from '@mui/icons-material';
import { ThemeSettings } from '../../../../../theme';
import { BlockSelectionCss } from '../../../../../toolympus/components/PowerDoc/elements/Common';

export const TopicLinkBlockType = "topic_link_block";

const TopicChip = styled(Chip)`
`;
TopicChip.defaultProps = { size: "small", color: "secondary" };

const TopicWrapperInner = styled(Buttons)<{ isSelected?: boolean }>`
  ${props => BlockSelectionCss(props)}
`;

const TopicWrapperOuter = styled.div`
  cursor: pointer;

  & + & {
    margin-top: 0.25rem;
  }

  & + p {
    margin-top: 0.25rem;
  }

  &:hover {
    & + p {
      background: ${ThemeSettings.colors.secondaryShade.s025};
      box-shadow: 0 0 20px -5px ${ThemeSettings.colors.secondaryShade.s025};
    }
  }
`;

export const TopicLinkBlock = (props: RenderElementProps) => {
  const isSelected = useSelected();

  const blocksCount = (props.element as any).blocks_count || 1;

  return (
    <TopicWrapperOuter {...props.attributes}>
        {props.children}
        
        <TopicWrapperInner contentEditable={false} isSelected={isSelected}>
          <TopicChip
            icon={<LabelOutlined />}
            label={<>
              <DictionarySelectDisplay field="topic_id" row={props.element} schema={{ dictionary: "Topics"}}  />
              {blocksCount > 1 && <span> ({blocksCount} абзаца)</span>}
            </>}
            />
        </TopicWrapperInner>
    </TopicWrapperOuter>
);
}
